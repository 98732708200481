import { useState } from 'react';
import { IconButton, Modal, Box, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

function HelpButton() {
  const [helpOpen, setHelpOpen] = useState(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  return (
    <>
      <IconButton
        color="default"
        onClick={() => setHelpOpen(true)}
      >
        <HelpIcon />
      </IconButton>
      
      <Modal
        open={helpOpen}
        onClose={() => setHelpOpen(false)}
      >
        <Box sx={modalStyle}>
          <Typography sx={{ mb: 3 }}>
            Sometimes, we can't remember the exact word we're looking for—or we're unsure how to spell it.<br/>
            That's where Wuzzling comes in! Simply type what you remember, and Wuzzling will suggest the most likely matches.
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default HelpButton; 