import { Container, CssBaseline, ThemeProvider, createTheme, Box } from '@mui/material';
import Header from './components/Header';
import SearchTabs from './components/SearchTabs';
import TallyForm from './components/Tally';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Header />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1 }}>
            <SearchTabs />
          </Box>
        </Box>
        <Box>
          <TallyForm></TallyForm>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App; 