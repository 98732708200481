import axios from 'axios';
import { API_HOST } from '../config';

export const useSearchUtils = (setResults, setError, setLoading) => {
  const handleWordClick = async (word) => {
    try {
      const response = await axios.get(`${API_HOST}/detail?w=${encodeURIComponent(word)}`);
      setResults(prevResults => 
        prevResults.map(result => 
          result.word === word 
            ? { ...result, details: response.data.details }
            : result
        )
      );
    } catch (err) {
      console.error('Failed to fetch details:', err);
    }
  };

  const handleSearch = async (query, endpoint) => {
    setLoading(true);
    try {
      const response = await axios.get(endpoint);
      setResults(response.data.results || []);
      setError('');
    } catch (error) {
      console.error('Error fetching results:', error);
      setError('Failed to fetch results. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return {
    handleWordClick,
    handleSearch,
  };
};

export const searchStyles = {
  searchButton: { 
    minWidth: '56px',
    width: '56px',
    height: '56px',
    padding: 0
  },
  resultsContainer: { 
    display: 'flex', 
    flexDirection: 'column', 
    gap: 2 
  },
  searchContainer: { 
    display: 'flex', 
    gap: 2, 
    mb: 1 
  },
  loadingContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    my: 3
  }
};
