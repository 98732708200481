import { Box } from '@mui/material';
import wuzzlingLogo from '../assets/wuzzling.png';

function Header() {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        py: 4
      }}
    >
      <img 
        src={wuzzlingLogo} 
        alt="Wuzzling Logo" 
        style={{ 
          height: '80px',
          width: 'auto'
        }} 
      />
    </Box>
  );
}

export default Header; 