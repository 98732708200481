import { useState } from 'react';
import { TextField, Box, CircularProgress, Button, FormHelperText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ResultCard from './ResultCard';
import InputValidator from './Validator';
import { useSearchUtils, searchStyles } from './SearchUtils';
import { API_HOST } from '../config';

function FuzzySearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { handleWordClick, handleSearch } = useSearchUtils(setResults, setError, setLoading);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!InputValidator(query)) {
      setError('Input should be 2 to 50 long without special chars.');
      return;
    }
    await handleSearch(query, `${API_HOST}/fuzzy?w=${encodeURIComponent(query)}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={searchStyles.searchContainer}>
        <TextField
          fullWidth
          label="What's it like in your memory?"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          variant="outlined"
          onKeyPress={handleKeyPress}
        />
        <Button 
          variant="contained" 
          type="submit"
          disabled={loading}
          sx={searchStyles.searchButton}
        >
          <SearchIcon />
        </Button>
      </Box>
      <FormHelperText style={{color: 'red'}}>{error}</FormHelperText>
      {loading && (
        <Box sx={searchStyles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={searchStyles.resultsContainer}>
        {results.map((result, index) => (
          <ResultCard 
            key={index} 
            result={result} 
            onWordClick={handleWordClick}
            index={index}
          />
        ))}
      </Box>
    </Box>
  );
}

export default FuzzySearch;