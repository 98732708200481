import React from 'react';
import { Card, CardContent, Typography, Box, IconButton, Collapse, CircularProgress, Link } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function ResultCard({ result, onWordClick, index }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(index < 3);

  // Reset expanded state when result changes
  React.useEffect(() => {
    // Expand if it's one of the first 3 cards or if details are available
    setIsExpanded(index < 3 || !!result?.details);
  }, [result, index]);

  const getAudioUrl = () => {
    if (!result?.details?.phonetics) return null;
    // Find the first phonetic entry with a non-empty audio URL
    const phoneticWithAudio = result.details.phonetics.find(p => p?.audio);
    return phoneticWithAudio?.audio || null;
  };

  const playAudio = (e) => {
    e?.stopPropagation();
    const audioUrl = getAudioUrl();
    if (audioUrl) {
      new Audio(audioUrl).play();
    }
  };

  const handleClick = async () => {
    if (!result.details && !isLoading) {
      setIsLoading(true);
      await onWordClick(result.word);
      setIsLoading(false);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            cursor: 'pointer' 
          }}
          onClick={handleClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6">{result.word}</Typography>
            <Link
              href={`https://www.merriam-webster.com/dictionary/${encodeURIComponent(result.word)}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: 'text.secondary',
                '&:hover': { color: 'primary.main' }
              }}
            >
              <OpenInNewIcon fontSize="small" />
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isLoading && <CircularProgress size={20} sx={{ mr: 1 }} />}
            <IconButton size="small">
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>

        <Collapse in={isExpanded && result.details}>
          {result.details && (
            <>
              {result.details.phonetic && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  mt: 1,
                  color: 'text.secondary'
                }}>
                  <Typography>{result.details.phonetic}</Typography>
                  {getAudioUrl() && (
                    <IconButton 
                      size="small" 
                      onClick={playAudio}
                      sx={{ ml: 1 }}
                    >
                      <VolumeUpIcon />
                    </IconButton>
                  )}
                </Box>
              )}
              {(!result.details.meanings || result.details.meanings.length === 0) ? (
                <Typography 
                  color="text.secondary" 
                  sx={{ 
                    mt: 2,
                    fontStyle: 'italic',
                    textAlign: 'center'
                  }}
                >
                  No definition found for this word
                </Typography>
              ) : (
                result.details.meanings.map((meaning, index) => (
                  <Box key={index} sx={{ mt: 2 }}>
                    <Typography color="text.secondary" variant="subtitle2">
                      {meaning.partOfSpeech}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
                      {meaning.definitions.map((def, defIndex) => (
                        <Box key={defIndex}>
                          <Typography variant="body2">
                            • {def.definition}
                          </Typography>
                          {def.example && (
                            <Typography variant="body2" color="text.secondary" sx={{ ml: 2, mt: 0.5 }}>
                              Example: "{def.example}"
                            </Typography>
                          )}
                        </Box>
                      ))}
                      {(meaning.synonyms?.length > 0 || meaning.antonyms?.length > 0) && (
                        <Box sx={{ mt: 1, ml: 2 }}>
                          {meaning.synonyms?.length > 0 && (
                            <Typography variant="body2" color="text.secondary">
                              Synonyms: {meaning.synonyms.join(', ')}
                            </Typography>
                          )}
                          {meaning.antonyms?.length > 0 && (
                            <Typography variant="body2" color="text.secondary">
                              Antonyms: {meaning.antonyms.join(', ')}
                            </Typography>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))
              )}
            </>
          )}
        </Collapse>
      </CardContent>
    </Card>
  );
}

export default ResultCard;