import { useState } from 'react';
import { Box, Tab, Tabs, Paper, Typography } from '@mui/material';
import FuzzySearch from './FuzzySearch';
import DescribeSearch from './DescribeSearch';
import HelpButton from './HelpButton';

function SearchTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Box sx={{ mr: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom align='center' color='#5C5C5C'>
            <span>Can’t find the right word?</span> <span>Wuzzling&nbsp;helps!</span>
            <HelpButton />
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab label="By memory" />
            <Tab label="By description" />
          </Tabs>
            <Box sx={{ ml: 2 }}>
            </Box>
        </Box>
      </Box>
      <Box>
        {value === 0 && <FuzzySearch />}
        {value === 1 && <DescribeSearch />}
      </Box>
    </Paper>
  );
}

export default SearchTabs; 