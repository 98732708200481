import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Backdrop } from '@mui/material';
import Iframe from 'react-iframe'

const TallyForm = () => {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    if (showFeedback && window.Tally) {
      window.Tally.loadEmbeds();
    }
  }, [showFeedback]);

  return (
    <Box>
        <Box
            sx={{
                position: "fixed",
                bottom: 20,
                right: 20,
                zIndex: 1000
            }}
        >
        <Button
            variant="contained"
            color="primary"
            onClick={() => setShowFeedback(!showFeedback)}
        >
            Feedback
        </Button>
        </Box>
        <Modal
          open={showFeedback}
          onClose={() => setShowFeedback(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: 600,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 2,
            }}
          >
            <Iframe
              url="https://tally.so/embed/mV989a?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
              width="100%"
              height="237"
              frameBorder="0"
              display="block"
              title="Wuzzling"
            ></Iframe>
          </Box>
        </Modal>
    </Box>
  );
};

export default TallyForm;